import { getPageInformation } from "@/app/api/admin";
import {
  getCandidateScreenings,
  getScreenerLocation,
} from "@/app/api/screeners";
import { DataDumpResponse } from "@/types/api";

const initializeAdmin = async ({ atsDomain }: { atsDomain: string }) => {
  parent.postMessage(
    {
      atsDomain: atsDomain,
      type: "upwg_adminInitialized",
    },
    "*",
  );
};

const sendJobPageData = async (pageInformation: DataDumpResponse) => {
  const screenerLocationId = pageInformation.screenerLocationIds[0];

  if (!screenerLocationId) {
    parent.postMessage(
      {
        iconText: null,
        pageWidth: "full",
        type: "upwg_adminStateChange",
      },
      "*",
    );
    return;
  }

  const screenerLocation = await getScreenerLocation(screenerLocationId);

  parent.postMessage(
    {
      iconText: `View ${screenerLocation.screenerSessions.length} screened candidates for this position!`,
      pageWidth: "full",
      type: "upwg_adminStateChange",
    },
    "*",
  );
};

const sendCandidatePageData = async (pageInformation: DataDumpResponse) => {
  const { candidateEmails } = pageInformation;
  const candidateEmail = candidateEmails[0];
  const candidate = await getCandidateScreenings(candidateEmail);
  parent.postMessage(
    {
      iconText: `View ${candidate.screenerSessions.length} screeners for this candidate!`,
      pageWidth: "candidate",
      type: "upwg_adminStateChange",
    },
    "*",
  );
};
// send dom to backend to get page information
const sendPageData = async ({
  companyId,
  dom,
}: {
  companyId: string;
  dom: string;
}) => {
  const pageInformation = await getPageInformation(companyId, dom);

  if (pageInformation.page === "job") {
    sendJobPageData(pageInformation);
  } else if (pageInformation.page === "candidate") {
    sendCandidatePageData(pageInformation);
  } else {
    parent.postMessage(
      {
        iconText: null,
        pageWidth: "full",
        type: "upwg_adminStateChange",
      },
      "*",
    );
  }
  return pageInformation;
};

const closeExtension = () => {
  parent.postMessage({ type: "upwg_setExtensionOpenState", open: false }, "*");
};

export { initializeAdmin, sendPageData, closeExtension };
