import orderBy from "lodash/orderBy";

import type { ScreenerResponse, Screening } from "@/types/screeners";

export const addHighPercentileIndicators = ({
  screenerSessions,
  ...response
}: ScreenerResponse) => {
  const getHighPercentileSessionIds = (
    screenings: Screening[],
    field: "totalTimeInSeconds" | "totalWords",
  ): string[] => {
    if (screenings.length === 0) {
      return [];
    }

    if (screenings.length === 1) {
      return [screenings[0].sessionId];
    }

    const orderedScreenings = orderBy<Screening>(screenings, field, "asc");
    const percentileIndex = 0.7 * (orderedScreenings.length - 1) + 1;
    const highPercentileScreenings = orderedScreenings.slice(
      Math.floor(percentileIndex),
    );
    const lowestHighPercentileValue = highPercentileScreenings[0][field];
    // If two or more users at the bottom of the percentile have the exact same
    // time spent or word count include them all.
    const firstHighPercentileValueIndex = orderedScreenings.findIndex(
      (screening) => screening[field] === lowestHighPercentileValue,
    );

    return orderedScreenings
      .slice(firstHighPercentileValueIndex)
      .map(({ sessionId }) => sessionId);
  };

  const highTimeSpent = getHighPercentileSessionIds(
    screenerSessions,
    "totalTimeInSeconds",
  );
  const highWordCount = getHighPercentileSessionIds(
    screenerSessions,
    "totalWords",
  );

  return {
    screenerSessions: screenerSessions.map(({ sessionId, ...session }) => ({
      ...session,
      sessionId,
      highTimeSpent: highTimeSpent.includes(sessionId),
      highWordCount: highWordCount.includes(sessionId),
    })),
    ...response,
  };
};
