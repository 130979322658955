import { useRouter } from "next/navigation";
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { userService } from "@/app/api/user";
import { useIdentifyUserAnalyticsEffect } from "@/hooks/analytics.hooks";
import type { User, UserContextType } from "@/types/user";
import type { Nullable } from "@/types/utils";
import { initializeAdmin } from "@/utils/extension";

const UserContext = createContext<UserContextType>({
  company: null,
  login() {
    throw new Error("Attempted to log in before user context initialized");
  },
  logOut() {},
  user: null,
});

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<Nullable<User>>(null);
  const router = useRouter();

  const login = (email: string, password: string) => {
    return userService
      .login(email, password)
      .then((user) => {
        setUser(user);
        initializeAdmin({ atsDomain: user.company.atsDomain || "" });

        return user;
      })
      .catch((error) => {
        if (error.status === 403 || error.status === 401) {
          logOut();
          initializeAdmin({ atsDomain: "" });
        }

        throw error;
      });
  };

  const logOut = () => {
    userService.logOut();
    setUser(null);
    router.push("/auth/login");
  };

  useIdentifyUserAnalyticsEffect(user);

  useEffect(() => {
    userService
      .initialize(logOut)
      .then((user) => {
        if (user) {
          setUser(user);
        }

        initializeAdmin({ atsDomain: user?.company.atsDomain ?? "" });

        // On load, if the user is not logged in and is not on one of the auth routes, redirect them to login
        if (!user && !window.location.pathname.startsWith("/auth")) {
          router.push("/auth/login");
        }
      })
      .catch(() => {
        initializeAdmin({ atsDomain: "" });
      });
    // We want this to run on first init only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{ company: user?.company ?? null, login, logOut, user }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
